(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["axios"], factory);
	else if(typeof exports === 'object')
		exports["axiosCacheAdapter"] = factory(require("axios"));
	else
		root["axiosCacheAdapter"] = factory(root[undefined]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_axios__) {
return 