const allCountryAndRegion = {
  regions: [
    {
      name: 'Alabama',
      shortCode: 'AL',
    },
    {
      name: 'Alaska',
      shortCode: 'AK',
    },
    {
      name: 'American Samoa',
      shortCode: 'AS',
    },
    {
      name: 'Arizona',
      shortCode: 'AZ',
    },
    {
      name: 'Arkansas',
      shortCode: 'AR',
    },
    {
      name: 'California',
      shortCode: 'CA',
    },
    {
      name: 'Colorado',
      shortCode: 'CO',
    },
    {
      name: 'Connecticut',
      shortCode: 'CT',
    },
    {
      name: 'Delaware',
      shortCode: 'DE',
    },
    {
      name: 'District of Columbia',
      shortCode: 'DC',
    },
    {
      name: 'Micronesia',
      shortCode: 'FM',
    },
    {
      name: 'Florida',
      shortCode: 'FL',
    },
    {
      name: 'Georgia',
      shortCode: 'GA',
    },
    {
      name: 'Guam',
      shortCode: 'GU',
    },
    {
      name: 'Hawaii',
      shortCode: 'HI',
    },
    {
      name: 'Idaho',
      shortCode: 'ID',
    },
    {
      name: 'Illinois',
      shortCode: 'IL',
    },
    {
      name: 'Indiana',
      shortCode: 'IN',
    },
    {
      name: 'Iowa',
      shortCode: 'IA',
    },
    {
      name: 'Kansas',
      shortCode: 'KS',
    },
    {
      name: 'Kentucky',
      shortCode: 'KY',
    },
    {
      name: 'Louisiana',
      shortCode: 'LA',
    },
    {
      name: 'Maine',
      shortCode: 'ME',
    },
    {
      name: 'Marshall Islands',
      shortCode: 'MH',
    },
    {
      name: 'Maryland',
      shortCode: 'MD',
    },
    {
      name: 'Massachusetts',
      shortCode: 'MA',
    },
    {
      name: 'Michigan',
      shortCode: 'MI',
    },
    {
      name: 'Minnesota',
      shortCode: 'MN',
    },
    {
      name: 'Mississippi',
      shortCode: 'MS',
    },
    {
      name: 'Missouri',
      shortCode: 'MO',
    },
    {
      name: 'Montana',
      shortCode: 'MT',
    },
    {
      name: 'Nebraska',
      shortCode: 'NE',
    },
    {
      name: 'Nevada',
      shortCode: 'NV',
    },
    {
      name: 'New Hampshire',
      shortCode: 'NH',
    },
    {
      name: 'New Jersey',
      shortCode: 'NJ',
    },
    {
      name: 'New Mexico',
      shortCode: 'NM',
    },
    {
      name: 'New York',
      shortCode: 'NY',
    },
    {
      name: 'North Carolina',
      shortCode: 'NC',
    },
    {
      name: 'North Dakota',
      shortCode: 'ND',
    },
    {
      name: 'Northern Mariana Islands',
      shortCode: 'MP',
    },
    {
      name: 'Ohio',
      shortCode: 'OH',
    },
    {
      name: 'Oklahoma',
      shortCode: 'OK',
    },
    {
      name: 'Oregon',
      shortCode: 'OR',
    },
    {
      name: 'Palau',
      shortCode: 'PW',
    },
    {
      name: 'Pennsylvania',
      shortCode: 'PA',
    },
    {
      name: 'Puerto Rico',
      shortCode: 'PR',
    },
    {
      name: 'Rhode Island',
      shortCode: 'RI',
    },
    {
      name: 'South Carolina',
      shortCode: 'SC',
    },
    {
      name: 'South Dakota',
      shortCode: 'SD',
    },
    {
      name: 'Tennessee',
      shortCode: 'TN',
    },
    {
      name: 'Texas',
      shortCode: 'TX',
    },
    {
      name: 'Utah',
      shortCode: 'UT',
    },
    {
      name: 'Vermont',
      shortCode: 'VT',
    },
    {
      name: 'Virgin Islands',
      shortCode: 'VI',
    },
    {
      name: 'Virginia',
      shortCode: 'VA',
    },
    {
      name: 'Washington',
      shortCode: 'WA',
    },
    {
      name: 'West Virginia',
      shortCode: 'WV',
    },
    {
      name: 'Wisconsin',
      shortCode: 'WI',
    },
    {
      name: 'Wyoming',
      shortCode: 'WY',
    },
    {
      name: 'Armed Forces Americas',
      shortCode: 'AA',
    },
    {
      name: 'Armed Forces Europe, Canada, Africa and Middle East',
      shortCode: 'AE',
    },
    {
      name: 'Armed Forces Pacific',
      shortCode: 'AP',
    },
  ],
};

export default allCountryAndRegion;
