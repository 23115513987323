import axios from 'axios';
import * as csv from 'csvtojson';
import missed_ads_csv from '../assets/missed_ads20201028.csv'

// const SOURCE_URL =
  // '%PUBLIC_URL%/missed_ads20201028.csv';

export const fetchData = async () => {
  const { data } = await axios.get(missed_ads_csv);
  const json = await csv().fromString(data);
  return json.sort((a, b) => a.ordering - b.ordering);
};

export default {
  fetchData,
};
