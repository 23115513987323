import Container from './Container';
import Row from './Row';
import Cell from './Cell';
import Body from './Body';

const Table = {
  Container,
  Body,
  Row,
  Cell,
};

export default Table;
